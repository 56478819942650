/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {    
  if ($.fn.style) {
    return;
  }

  // Escape regex chars with \
  var escape = function(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  // For those who need them (< IE 9), add support for CSS functions
  var isStyleFuncSupported = !!CSSStyleDeclaration.prototype.getPropertyValue;
  if (!isStyleFuncSupported) {
    CSSStyleDeclaration.prototype.getPropertyValue = function(a) {
      return this.getAttribute(a);
    };
    CSSStyleDeclaration.prototype.setProperty = function(styleName, value, priority) {
      this.setAttribute(styleName, value);
      var priority = typeof priority != 'undefined' ? priority : '';
      if (priority != '') {
        // Add priority manually
        var rule = new RegExp(escape(styleName) + '\\s*:\\s*' + escape(value) +
            '(\\s*;)?', 'gmi');
        this.cssText =
            this.cssText.replace(rule, styleName + ': ' + value + ' !' + priority + ';');
      }
    };
    CSSStyleDeclaration.prototype.removeProperty = function(a) {
      return this.removeAttribute(a);
    };
    CSSStyleDeclaration.prototype.getPropertyPriority = function(styleName) {
      var rule = new RegExp(escape(styleName) + '\\s*:\\s*[^\\s]*\\s*!important(\\s*;)?',
          'gmi');
      return rule.test(this.cssText) ? 'important' : '';
    }
  }

  // The style function
  $.fn.style = function(styleName, value, priority) {
    // DOM node
    var node = this.get(0);
    // Ensure we have a DOM node
    if (typeof node == 'undefined') {
      return this;
    }
    // CSSStyleDeclaration
    var style = this.get(0).style;
    // Getter/Setter
    if (typeof styleName != 'undefined') {
      if (typeof value != 'undefined') {
        // Set style property
        priority = typeof priority != 'undefined' ? priority : '';
        style.setProperty(styleName, value, priority);
        return this;
      } else {
        // Get style property
        return style.getPropertyValue(styleName);
      }
    } else {
      // Get CSSStyleDeclaration
      return style;
    }
  };
})(jQuery);

(function($) {

  	function isiPhone()
  	{
	    if(window.innerWidth < 767)
	    {
	    	return true;
	    }
	    return false;
  	}

  	function isiPad()
  	{
  		// 1024 / 768
  		if(window.innerWidth < 1024)
  		{
  			return true;
  		}

  		//return navigator.userAgent.match(/iPad/i) != null;
  	}
	

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).ready(function(){
        
        	$("a[href='#']").click(function(e){
        		e.preventDefault();
        	});

        	$("a[href='#contact']").click(function(e){
        		e.preventDefault();
        		$('#contactModal').modal('show');
        	});

        	$("ul.navigation-menu > li > a.has-submenu").click(function(e){
        		e.preventDefault();

        		if($(this).hasClass("open"))
        		{
        			$(this).removeClass("open");
        			$(this).siblings(".nav-submenu").slideUp(175);
        		}
        		else
        		{
        			$("ul.navigation-menu > li > a").removeClass("open");
        			$(".nav-submenu").slideUp(175);
        			$(this).addClass("open");
        			$(this).siblings(".nav-submenu").slideDown(175);
        		}
        	});

        	//$("img.lazy").lazyload({effect: "fadeIn", container: $(".content-container")});

        	var pageURL = window.location;

        	$("ul.navigation-menu li a").each(function(index, el){
        		if($(el).prop("href") == pageURL)
        		{
        			if($(el).closest("ul").hasClass("nav-submenu"))
        			{
        				// it is a sub-menu item
        				$(el).closest("ul").slideDown(175).siblings("a").eq(0).addClass("open");
        				$(el).addClass("active");
        			}
        			else
        			{
        				// it is a top level page

        				$(el).addClass("active");
        			}
        			return false;
        		}
        	});

        	if($(".content-section-first").length > 0)
        	{
        		// we're on an informational page
        		$(window).load(function(){
        			window.setTimeout(function(){
        				var height = $(".navigation-menu-wrapper").height();
        				$(".content-section-first").css({"height": (height+55)+"px"});
        			}, 25);
        		});
        	}
        	

        	if(window.innerWidth > 767)
        	{
        		$(".blog-navigation li").hover(function(){
	        		$(this).find("ul").stop().slideDown(175);
	        	}, function(){
	        		$(this).find("ul").stop().slideUp(175);
	        	});
        	}
        	else
        	{
			    $("#blogNavbarToggle").click(function(e){
        			e.preventDefault();
        			
        			$(".blog-navigation").stop().slideToggle(175);
        		});
        	}

        	if(window.innerWidth < 1024)
        	{
        		$("#navbarToggle").click(function(e){
			        e.preventDefault();
			        $(this).toggleClass("active");
			        $(".navigation-menu-wrapper").toggleClass("slide");
			    });
        	}

        	var currentURL = window.location.href;

        	/*$(".article-page img").each(function(index, el){
        		//console.log($(el).prop("src"));
        		beforeHTML = "<a target='_blank' href='http://pinterest.com/pin/create/button/?url="+ encodeURIComponent(currentURL) +"&media="+ encodeURIComponent($(el).prop("src")) +"&description="+ encodeURIComponent('Mary Costa Photography')+"'>Pin This</a>";
				$(el).wrap('<div class="pinterest-image"></div>');
        		$(beforeHTML).insertBefore(el);
        	});*/

        	$('#blogCarousel').carousel({
		  		interval: false
			});

        	/* Form validation and Form Logic */

        	if(document.querySelector("#gform_1")) {
        		document.querySelector("#gform_1").onsubmit = function(){return false;};
        	}
        	if(document.querySelector("#searchForm")) {
        		document.querySelector("#searchForm").onsubmit = function(){return false;};
        	}

        	$("#openComments").click(function(e){
        		e.preventDefault();
        		$(".comment-form").slideDown(175);
        	});

        	$("#showComments").click(function(e){
        		e.preventDefault();
        		$(".comments-wrapper").slideDown(175);
        	});

        	$(".search-submit").click(function(e){
        		e.preventDefault();

        		if($(".search-field").val().trim().length === 0)
        		{
        			$("p.modal-error").css("opacity", "1");
        			return;
        		}

        		document.querySelector("#searchForm").submit();
        	});

        	if($(".blog-nav-wrapper").length > 0)
        	{
        		if(window.innerWidth > 767)
        		{
        			var originalOffset = $(".blog-nav-wrapper").offset().top;
	        		$(window).scroll(function(e){
	        			var height = $(".blog-nav-wrapper").height();
	        			height = height + "px";

	        			if($(document).scrollTop() > originalOffset)
	        			{
	        				$(".blog-nav-wrapper").css({"position" : "fixed", "top" : "0"});
	        				$(".blog-header").css({'margin-top' : height});
	        			}
	        			else
	        			{
	        				$(".blog-nav-wrapper").css({"position" : "relative", "top" : "0"});
	        				$(".blog-header").css({'margin-top' : "0"});
	        			}
	        		});
        		}
        	}

        	if($(".comment-form-comment").length > 0)
        	{
        		var commentInput = $(".comment-form-comment")[0].outerHTML;
	        	$(".comment-form-comment textarea").attr("placeholder", "Comment");
	        	$(".comment-form-comment").remove();
	        	$(".comment-form-author input").attr("placeholder", "Name");
	        	$(".comment-form-email input").attr("placeholder", "Email");
	        	$(".comment-form-url input").attr("placeholder", "Website");
	        	$(commentInput).insertAfter(".comment-form-url");
        	}

        	// validate the form in the contact popup
        	$("#gform_submit_button_1").click(function(e){
        		e.preventDefault();

        		// first, see if any of the basic inputs have values
        		var inputs = $(".validate-empty input");
        		var passedValidation = true;
        		
        		for(var i = 0; i < inputs.length; i++)
        		{
        			if($(inputs[i]).val().trim().length === 0)
        			{
        				passedValidation = false;
        				$(inputs[i]).addClass("error-message");
        			}
        			else
        			{
        				$(inputs[i]).removeClass("error-message");
        			}
        		}

        		if(!passedValidation)
        		{
        			$("p.modal-error").css("opacity", "1");
        			$("#contactModal").animate({scrollTop : 100});
        			return;
        		}

        		var email = $("#input_1_2").val();
        		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			
    			if(!re.test(email))
    			{
    				$("#input_1_2").addClass("error-message");
    				$("p.modal-error").css("opacity", "1").html("Please enter a valid email address below.");
        			$("#contactModal").animate({scrollTop : 100});
    				return;
    			}

    			$("#input_1_2").removeClass("error-message");

    			// we made it this far, so submit the form

    			$("p.modal-error").fadeOut(175);
    			$(".form-loader").fadeIn(175);
    			document.querySelector("#gform_1").submit();
        	});
        });

		$(document).bind('gform_confirmation_loaded', function(event, formId){
		   	if(formId === 1)
		   	{
		   		$(".form-loader").fadeOut(175);
		   		$(".gform_confirmation_message_1").fadeIn(175);
		   	}
		});



		
        
      
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
